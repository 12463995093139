@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

* {
  font-family: 'Inter';
  margin: 0;
  text-decoration: none;
}

html, body, #root {
  height: 100%;
  width: 100%;
}

.center {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.center>* {
  display: block;
  margin: auto;
  text-align: center;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea {
  outline: none;
  resize: none;
  font-family: "Roboto";
  border: none;
  -moz-appearance: textfield; 
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: 0px;
  font-family: 'Roboto';
  text-align: center;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  font-family: 'Arial', sans-serif;
  background-color: #29313E;
  color: #ecf0f1;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table thead tr {
  background-color: #3C4656;
  text-align: left;
  font-weight: bold;
}

.styled-table tbody tr {
  border-bottom: 1px solid #34495e;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #3C4656;
}

.styled-table tbody tr:last-of-type {
  
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #ecf0f1;
}

.styled-table img {
  height: 50px; /* Adjust as needed */
  width: auto;
}

.element-with-scrollbar::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.element-with-scrollbar::-webkit-scrollbar-track {
  background: #0b0d12;
  border-radius: 10px;
}

.element-with-scrollbar::-webkit-scrollbar-thumb {
  background: #FFFFFF;
  border-radius: 10px;
}
